import React from 'react'
import { Link } from 'react-router-dom'

function ClientHeader() {
    const appName = process.env.REACT_APP_APP_NAME

    return (
        <div className={'align-content-center text-center'}>
            <Link to={'/'}>
                <img src={'/logo192.png'} width="140" className="logo-icon" alt="logo icon" />
            </Link>

            <h2>{appName}</h2>
        </div>
    )
}
export default ClientHeader
