import React from "react";
import { ROLE_NAME } from "./roleName";


const Home = React.lazy( () => import('../components/client/Home') )
const Dashboard = React.lazy( () => import('../components/client/Dashboard/ClientDashboard') )
const Users = React.lazy( () => import('../components/admin/users/Users') )
const Roles = React.lazy( () => import('../components/admin/users/roles/Roles') )
const Profile = React.lazy(() => import('../components/admin/users/Profile'))
const Properties = React.lazy(() => import('../components/admin/properties/Properties'))
const Lease = React.lazy(() => import('../components/admin/leases/Lease'))
const ViewLease = React.lazy(() => import('../components/admin/leases/ViewLease'))
const RentInvoice = React.lazy(() => import('../components/admin/leases/RentInvoice'))
const CreateLease = React.lazy(() => import('../components/client/leases/CreateLease'))
const LeaseStart = React.lazy(() => import('../components/client/leases/Search'))
const Login = React.lazy(() => import('../components/admin/account/Login'))
const CreateAccount = React.lazy(() => import('../components/admin/account/CreateAccount'))
const Currencies = React.lazy(() => import('../components/admin/currencies/Currencies'))
const Settings = React.lazy(() => import('../components/admin/settings/SettingsComponent'))
const Contact = React.lazy(() => import('../components/client/Contact/Contact'))
const Issues = React.lazy(() => import('../components/admin/issues/Issues'))
const ViewIssue = React.lazy(() => import('../components/admin/issues/ViewIssue'))

const routes = [
	{path: '/', name: 'Home', exact:true, component: Home, roles: [ROLE_NAME.NONE]},
	{path: '/login', name: 'Login', exact:true, component: Login, roles: [ROLE_NAME.NONE]},
	{path: '/create-account', name: 'Create Account', exact:true, component: CreateAccount, roles: [ROLE_NAME.NONE]},
	{path: '/profile', name: 'Profile', exact:true, component: Profile, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN]},
	{path: '/admin/dashboard', name: 'Dashboard', exact:true, component: Dashboard, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN]},
	{path: '/admin/users', name: 'Users', exact:true, component: Users, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN]},
	{path: '/admin/roles', name: 'Roles', exact:true, component: Roles, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN]},
	{path: '/admin/properties', name: 'Properties', exact:false, component: Properties, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN,ROLE_NAME.MANAGER]},
	{path: '/admin/leases', name: 'Leases', exact:true, component: Lease, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN,ROLE_NAME.MANAGER]},
	{path: '/admin/leases/:id', name: 'View Lease', exact:true, component: ViewLease, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN,ROLE_NAME.MANAGER]},
	{path: '/admin/rent-invoice/:id', name: 'View Rent Invoice', exact:true, component: RentInvoice, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN,ROLE_NAME.MANAGER]},
	{path: '/leases', name: 'Leases', exact:true, component: Lease, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN,ROLE_NAME.MANAGER]},
	{path: '/leases/start', name: 'Create New Lease - Select User', exact:true, component: LeaseStart, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN,ROLE_NAME.MANAGER]},
	{path: '/leases/create/:tenantId', name: 'Create New Lease - Lease Terms', exact:true, component: CreateLease, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN,ROLE_NAME.MANAGER]},
	{path: '/leases/create/:propertyId/:tenantId', name: 'Create New Lease - Lease Terms', exact:true, component: CreateLease, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN,ROLE_NAME.MANAGER]},
	{path: '/leases/:id', name: 'View Lease', exact:true, component: ViewLease, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN,ROLE_NAME.MANAGER]},
	{path: '/properties', name: 'Properties', exact:false, component: Properties, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN,ROLE_NAME.MANAGER]},
	{path: '/admin/currencies', name: 'Currencies', exact:true, component: Currencies, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN]},
	{path: '/admin/settings', name: 'Settings', exact:true, component: Settings, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN]},
	{path: '/contact', name: 'Contact', exact:true, component: Contact, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN]},
	{path: '/admin/issues', name: 'Issues', exact:true, component: Issues, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN,ROLE_NAME.MANAGER]},
	{path: '/admin/issues/:id', name: 'View Issue', exact:true, component: ViewIssue, roles: [ROLE_NAME.ADMIN,ROLE_NAME.SUPER_ADMIN,ROLE_NAME.MANAGER]},
]

export default routes