import React, { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { get_axios_error } from '../../../helpers/general'
import AccountWrapper from './AccountWrapper'

function ActivateAccount() {
    const { token }: { token: string } = useParams()
    const [alert, setAlert] = useState({ show: false, message: '', variant: 'info', heading: 'Account Activation' })
    const [done, setDone] = useState(false)

    const activateAccount = useCallback(() => {
        console.log("activate account called with " + token)
        if(!!token) {
            window.axios.get('user-account/confirm/' + token)
                .then(response => {
                    console.log(response)
                    showAlert(response.data.message, 'info')
                })
                .catch((error) => {
                    console.log(error)
                    const e = get_axios_error(error)
                    showAlert(e.message, 'danger')
                })
        } else {
            showAlert("A valid account activation token is required", 'danger')
        }
    }, [token])

    useEffect(() => {
        if (!done) {
            setDone(true)
            activateAccount()
        }
    }, [activateAccount, done])

    const showAlert = (message: string, variant: string, heading: string = '') => {
        const data = { show: true, variant: variant, message: message, heading: '' }
        if (heading) data.heading = heading
        setAlert(data)
    }

    const hideAlert = () => {
        setAlert({ heading: '', message: '', variant: '', show: false })
    }

    return (
        <AccountWrapper>
            <div className='p-4 rounded'>
                <div className='text-center'>
                    <h3 className=''>Activate User Account</h3>
                    <p>Forgot your account password? <Link to={'/create-account'}>Reset password here</Link></p>

                    {alert.show &&
                        <Alert className='text-start' variant={alert.variant} onClose={() => hideAlert()} dismissible>
                            <Alert.Heading as={'h5'}>Account Activation</Alert.Heading>
                            <p className='mb-0'>{alert.message}</p>
                        </Alert>}

                    {!alert.show ? <p><img src={'/assets/images/icons/loading.svg'} alt='please wait...' /></p> :
                        <div>
                            <Link to={'/login'} className='btn btn-block my-4 shadow-sm btn-white'>
                                <span className='d-flex justify-content-center align-items-center'>
                                    <i className='bx bxs-lock-open' />Sign in here
                                </span>
                            </Link>
                            &nbsp;&nbsp;&nbsp;
                            <Link to={'/resend-activation-token'} className='btn btn-block my-4 shadow-sm btn-white'>
                                <span className='d-flex justify-content-center align-items-center'>
                                    <i className='bx bxs-envelope' />Resend activation token
                                </span>
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </AccountWrapper>
    )
}

export default ActivateAccount