import React, { useEffect } from 'react'
import 'core-js/es/map'
import 'core-js/es/set'
import 'core-js/es/promise'
import { BrowserRouter as Router, Route, Switch  } from 'react-router-dom'
import axios from 'axios'
import ResendActivationToken from './components/admin/account/ResendActivationToken'
import ActivateAccount from './components/admin/account/ActivateAccount'
import ResetForgottenPasswordRequest from './components/admin/account/ResetForgottenPasswordRequest'
import ResetForgottenPassword from './components/admin/account/ResetForgottenPassword'
import { useSelector } from 'react-redux'
import { useLoggedIn } from './helpers/session'
import { ReduxState } from './helpers/general'
import ProfileRoute from './helpers/ProfileRoute'

window.axios = axios
window.axios.defaults.baseURL = process.env.REACT_APP_API_URL
window.axios.defaults.withCredentials = false


const loading = (
    <div className="py-5 text-center">
        <div className="spinner-grow" style={{ width: '3rem', height: '3rem' }} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
)

const App = () => {
    const checkLogin = useLoggedIn()
    const general = useSelector((state: ReduxState) => state.general)
    const profile = useSelector((state: ReduxState) => state.profile)


    useEffect(() => {
        if (profile.loggedIn === 'wait') {
            checkLogin()
        }
    }, [checkLogin, profile.loggedIn])



    return (
        <div className={general.wrapperToggled ? 'wrapper toggled' : 'wrapper'}>
            <Router>
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/resend-activation-token"
                            render={(props: any) => (<ResendActivationToken {...props} />)}
                        />
                        <Route exact path="/forgot-password"
                            render={(props: any) => (<ResetForgottenPasswordRequest {...props} />)}
                        />
                        <Route exact path="/user-account/reset-password/:token"
                            render={(props: any) => (<ResetForgottenPassword {...props} />)}
                        />
                        <Route exact path="/user-account/confirm/:token"
                            render={(props: any) => (<ActivateAccount {...props} />)}
                        />
                        <ProfileRoute />
                    </Switch>
                </React.Suspense>
            </Router>
        </div>
    )
}

export default App
