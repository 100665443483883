import React, { useState } from 'react'
import GuestLayout from '../../../app/layouts/GuestLayout'
import { Alert } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { AlertProps, get_axios_error } from '../../../helpers/general'
import AccountWrapper from './AccountWrapper'

function ResetForgottenPassword() {
    const { token }: { token: string } = useParams()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [alert, setAlert] = useState<AlertProps>({
        show: false,
        message: '',
        variant: 'info',
        heading: 'Account Activation',
    })
    const [disabled, setDisabled] = useState(false)

    const passwordChanged = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setPassword(event.target.value)
    }

    const confirmPasswordChanged = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setConfirmPassword(event.target.value)
    }

    const showAlert = (message: string, variant?: string, heading?: string) => {
        const data: AlertProps = { show: true, variant: variant, message: message }
        if (heading) data.heading = heading
        setAlert(data)
    }

    const hideAlert = () => {
        setAlert({ show: false })
    }

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault()

        if (!token) {
            showAlert('A valid password reset token is required')
            return false
        }
        setDisabled(true)
        const form = { password: password, confirmPassword: confirmPassword, token: token }

        window.axios.post('user-account/reset-password', form)
            .then(response => {
                console.log(response)
                showAlert(response.data.message, 'info')
            })
            .catch((error) => {
                const e = get_axios_error(error)
                showAlert(e.message, 'danger')
            })
            .finally(() => setDisabled(false))
    }

    return (
        <GuestLayout>
            <AccountWrapper>
                <div className='p-4 rounded'>
                    <div className='text-center'>
                        <h3 className=''>Reset Forgotten Password</h3>
                        <p className='mb-1'>Already have an account? <Link to={'/login'}>Sign in here</Link></p>
                        <p>Don't have an account yet? <Link to={'/create-account'}>Register here</Link></p>
                    </div>
                    <div className='form-body'>
                        <form className='row g-3' onSubmit={handleSubmit}>
                            <div className='login-separater text-center mb-4'>
                                <span>Enter your new password below</span>
                                <hr />
                                {alert.show &&
                                    <Alert className='text-start' variant={alert.variant} onClose={() => hideAlert()}
                                           dismissible>
                                        <Alert.Heading as={'h5'}>Reset Forgotten Password</Alert.Heading>
                                        <p className='mb-0'>{alert.message}</p>
                                    </Alert>}
                            </div>
                            <div className='col-12'>
                                <label htmlFor='inputNewPassword' className='form-label'>New Password</label>
                                <input type='password' className='form-control' id='inputNewPassword'
                                       placeholder='New Password' autoComplete='off'
                                       onChange={passwordChanged} value={password} required />
                            </div>

                            <div className='col-12'>
                                <label htmlFor='inputConfirmPassword' className='form-label'>Confirm Password</label>
                                <input type='password' className='form-control' id='inputConfirmPassword'
                                       placeholder='Confirm New Password' autoComplete='off'
                                       onChange={confirmPasswordChanged} value={confirmPassword} required />
                            </div>

                            <div className='col-12'>
                                <div className='d-grid'>
                                    <button type='submit' className='btn btn-primary' disabled={disabled}>
                                        <i className='bx bxs-send bx-rotate-270' />Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </AccountWrapper>
        </GuestLayout>
    )
}

export default ResetForgottenPassword