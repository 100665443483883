import React from "react";
import {ToastContainer} from "react-bootstrap"
import { useSelector, useDispatch } from 'react-redux'
import {NotificationInterface, removeToast} from '../../features/notifications/NotificationSlice'
import "./Notifications.css"
import PausableToast from "./PausableToast";
import {ReduxState} from "../../helpers/general";

function Notifications(){
	
	const messages = useSelector((state:ReduxState) => state.notifications)
	const dispatch = useDispatch()
	
	const removeMessage = (id: string) => {
		dispatch(removeToast(id))
	}
	
	return(
			<ToastContainer position={'bottom-end'} className="position-fixed mb-5 me-4">
				{messages.map( (message:NotificationInterface) => {
					return(
						<PausableToast
							key={message.id}
							message={message}
							removeMessage={removeMessage}
						/>
					)
				})}
			</ToastContainer>
	)
}

export default Notifications