import { createSlice } from '@reduxjs/toolkit'
import { v4  } from "uuid"

export interface NotificationInterface{
	id: string,
	flag: string,
	message: string,
	heading?: string
}

const initialState:NotificationInterface[] = []

export const NotificationSlice = createSlice({
	name: 'notifications',
	initialState: initialState,
	reducers: {
		setError: (state, action) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			state.push({ id: v4(), flag: 'danger', message: action.payload })
		},
		setWarning: (state, action) => {
			const body = splitMessage(action.payload)
			state.push({ id: v4(), flag: 'warning', ...body })
		},
		setInfo: (state, action) => {
			const body = splitMessage(action.payload)
			state.push({ id: v4(), flag: 'info', ...body })
		},
		setSuccess: (state, action) => {
			const body = splitMessage(action.payload)
			state.push({ id: v4(), flag: 'success', ...body })
		},
		removeToast: (state, action) => {
			// console.log(action)
			const index = state.findIndex( item => item.id === action.payload )
			if( index >= 0) state.splice( index, 1 )
		},
		setMessage: (state, action) => {
			const body = splitMessage(action.payload)
			switch (action.type){
				case "setError" :
					state.push({ id: v4(), flag: 'danger', ...body })
					return state
				case "setWarning" :
					state.push({ id: v4(), flag: 'warning', ...body })
					return state
				case "setInfo" :
					state.push({ id: v4(), flag: 'info', ...body })
					return state
				case "setSuccess" :
					state.push({ id: v4(), flag: 'success', ...body })
					return state
				case "removeLastToast":
					state.pop()
					return state
				case "removeThisToast":
					const index:number|undefined = state.findIndex( (item:NotificationInterface) => item.id === action.payload )
					if(index!==undefined)state.splice(index, 1)
					return state
				case "removeAllToasts":
					state = [];
					return state
				default:
					return state
			}
		},
	},
})

const splitMessage = (payload:string) => {
	const components = payload.split('. ')
	if(components.length > 1 && components[0].length < 51){
		const body = payload.replace(components[0]+'. ', '')
		return {heading: components[0], message: body}
	}
	return {message: payload}
}

// Action creators are generated for each case reducer function
export const { setError, setWarning, setMessage, setInfo, setSuccess, removeToast } = NotificationSlice.actions

export default NotificationSlice.reducer