import { createSlice } from '@reduxjs/toolkit'
import { getProfile, setupProfile } from '../../helpers/auth'
import { ROLE_NAME } from '../../helpers/roleName'

export interface RoleInterface {
    name: ROLE_NAME
    alias: string
}

export interface ProfileInterface {
    role: RoleInterface
    roles: any[]
    username: string
    firstName: string
    lastName: string
    emailAddress: string
    id: string
    loggedIn: string | boolean
    phoneNumber: string
    enabled: boolean
    isSuperAdmin: boolean
    isAdmin: boolean
    isManager: boolean
    isTenant: boolean
}

const initialState: ProfileInterface = {
    role: { name: getProfile(), alias: '' },
    roles: [],
    username: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    enabled: false,
    id: '',
    loggedIn: 'wait',
    isSuperAdmin: false,
    isAdmin: false,
    isManager: false,
    isTenant: true
}

export const ProfileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        switchProfile: (state, action) => {
            console.log("SWITCH ROLE TO " + action.payload)
            console.log("CURRENT ROLES: ")
            console.log(state.roles)
            const role: RoleInterface | undefined = state.roles.find(
                (r: RoleInterface) => r.name === action.payload
            )
            if (role) {
                localStorage.setItem('profile', role.name)
                state.role = role
            } else {
                localStorage.setItem('profile', ROLE_NAME.GUEST)
                state.role = { name: ROLE_NAME.GUEST, alias: '' }
            }
        },
        setupUser: (state, action) => {
            const data = action.payload
            if (data.roles) {
                const roles = data.roles.map((role: RoleInterface) => role.name)
                if (!roles.includes(state.role.name)) {
                    const defaultRole = setupProfile(roles)
                    const role = data.roles.find(
                        (r: RoleInterface) => r.name === defaultRole
                    )
                    if (role) state.role = role
                    // state.roles = roles
                }
                const roleNames = data.roles.map((role: RoleInterface) => role.name)
                state.isSuperAdmin = roleNames.includes(ROLE_NAME.SUPER_ADMIN)
                state.isAdmin = (roleNames.includes(ROLE_NAME.SUPER_ADMIN) || roleNames.includes(ROLE_NAME.ADMIN))
                state.isManager = (roleNames.includes(ROLE_NAME.MANAGER) || roleNames.includes(ROLE_NAME.SUPER_ADMIN) || roleNames.includes(ROLE_NAME.ADMIN))
                state.isTenant = (roleNames.includes(ROLE_NAME.TENANT) || roleNames.includes(ROLE_NAME.MANAGER) || roleNames.includes(ROLE_NAME.SUPER_ADMIN) || roleNames.includes(ROLE_NAME.ADMIN))
            }
            state.roles = data.roles
            state.emailAddress = data.emailAddress
            state.firstName = data.firstName
            state.lastName = data.lastName
            state.username = data.username
            state.id = data.id
            state.phoneNumber = data.phoneNumber
            state.enabled = data.enabled
        },
        setLoginStatus: (state, action) => {
            // @ts-ignore
            window.loggedIn = action.payload
            state.loggedIn = action.payload
        },
        resetSession: (state) => {
            state.roles = []
            state.emailAddress = ''
            state.firstName = ''
            state.lastName = ''
            state.username = ''
            state.id = ''
            state.phoneNumber = ''
            state.enabled = false
            state.loggedIn = false
            state.isSuperAdmin = false
            state.isAdmin = false
            state.isManager = false
            state.isTenant = true
            localStorage.setItem('profile', ROLE_NAME.GUEST)
            state.role = { name: ROLE_NAME.GUEST, alias: '' }
        },
    },
})

// Action creators are generated for each case reducer function
export const { switchProfile, setupUser, setLoginStatus, resetSession } =
    ProfileSlice.actions
export default ProfileSlice.reducer
