import GuestLayout from '../../../app/layouts/GuestLayout'
import AccountWrapper from './AccountWrapper'

function PleaseWait() {
    return (
        <GuestLayout>
            <AccountWrapper>
                <div className='p-4 rounded'>
                    <div className='text-center'>
                        <h3 className=''>Checking your account</h3>
                        <p>
                            <img src={'/assets/images/icons/loading.svg'} alt='please wait...' />
                        </p>
                    </div>
                </div>
            </AccountWrapper>
        </GuestLayout>
    )
}

export default PleaseWait