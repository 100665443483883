import { useEffect } from 'react'
import { Accordion, Card, Col, Row } from 'react-bootstrap'
import {  useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { ProfileInterface } from '../../../features/auth/ProfileSlice'
import { ReduxState } from '../../../helpers/general'
import { ROLE_NAME } from '../../../helpers/roleName'


interface Props {
    profile: ProfileInterface
}

function LandlordHeader({ profile }: Props) {
    const general = useSelector((state: ReduxState) => state.general)

    useEffect(() => {

        const controller = new AbortController()
        let timer = 0

        return function cleanup() {
            controller.abort()
            if (timer) window.clearTimeout(timer)
        }
    }, [
        profile.loggedIn,
        profile.role.name,
        general.refreshStats,
    ])

    if (profile.loggedIn === true && profile.role.name === ROLE_NAME.MANAGER) {
        return (
            <Accordion defaultActiveKey="0" className="mb-5">
                <Card className="rounded-0">
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Row className="row-cols-1 row-cols-md-2 row-cols-xl-3 py-3">
                                <Col>
                                    <Card className="mb-0 radius-10">
                                        <Card.Body>
                                            <NavLink to={''} >
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <p className="mb-0 text-secondary">
                                                            Dashboard
                                                        </p>
                                                    </div>
                                                    <div className="text-primary ms-auto font-35">
                                                        <i className="bx bx-spreadsheet" />
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card className="mb-0 radius-10">
                                        <Card.Body>
                                            <NavLink
                                                to={'/properties'}
                                                className="d-flex align-items-center"
                                            >
                                                <div>
                                                    <p className="mb-0 text-secondary">
                                                        Properties
                                                    </p>
                                                </div>
                                                <div className="text-primary ms-auto font-35">
                                                    <i className="bx bx-building-house" />
                                                </div>
                                            </NavLink>
                                        </Card.Body>
                                    </Card>
                                    <div className="mb-3 d-block d-xl-none" />
                                </Col>

                                <Col>
                                    <Card className="mb-0 radius-10">
                                        <Card.Body>
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <p className="mb-0 text-secondary">
                                                        Agents
                                                    </p>
                                                </div>
                                                <div className="text-primary ms-auto font-35">
                                                    <i className="bx bx-user" />
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <div className="mb-3 d-block d-xl-none" />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    } else return null
}

export default LandlordHeader
