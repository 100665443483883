import axios from 'axios'
import { ROLE_NAME } from './roleName'
import { RoleInterface } from '../features/auth/ProfileSlice'

interface TokensInterface {
    accessToken: string
    accessTokenExpires: Date
    refreshToken: string
    refreshTokenExpires: Date
    roles: RoleInterface[]
}

export const useAuth = () => {
    const refreshTokenName = process.env.REACT_APP_REFRESH_TOKEN_NAME || 'ECqY2ibVlI1aSwh'
    const accessTokenName = process.env.REACT_APP_ACCESS_TOKEN_NAME || 'NMLz9wRTrCrQk4Q'

    const createSession = (username: string, tokens: TokensInterface, remember: any) => {
        localStorage.setItem('user', username)
        setCookie(accessTokenName, tokens.accessToken, {expires: tokens.accessTokenExpires})
        if (tokens.refreshToken) {
            const options = remember ? { expires: tokens.refreshTokenExpires } : {}
            setCookie(refreshTokenName, tokens.refreshToken, options)
        }
        return setupProfile(tokens.roles.map(role => role.name))
    }

    const setProfile = (profile: any) => {
        localStorage.setItem('profile', profile)
    }

    const destroySession = () => {
        const refreshToken = getRefreshToken();
        if(!!refreshToken) {
            axios.get('/token/revoke').finally(() => {
                deleteCookie(refreshTokenName)
            })
        }
        cleanupSession()
    }

    const isLoggedIn = () => {
        const user = localStorage.getItem('user')
        const profile = localStorage.getItem('profile')
        const token = getRefreshToken()
        if(user && token && profile){
            return true
        }
        cleanupSession()
        return false
    }

    const cleanupSession = () => {
        localStorage.removeItem('user')
        localStorage.removeItem('profile')

        deleteCookie(accessTokenName)
        deleteCookie(refreshTokenName)
    }
    const getRefreshToken = () => {
        return getCookie(refreshTokenName)
    }
    const setCookie = (name: string, value: string, options: any = {}) => {
        let cookieString = name + '=' + value + ';'
        if (!options.hasOwnProperty('path')) options.path = '/'

        if (options.hasOwnProperty('expires') ) {
            const date = typeof options.expires === 'number' ? new Date(options.expires * 1000) : new Date(options.expires)
            options.expires = date.toUTCString()
        }
        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                cookieString += ' ' + key + '=' + options[key] + ';'
            }
        }
        document.cookie = cookieString
    }

    const deleteCookie = (name: string) => {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }

    const getCookie = (name: string) => {
        const array = document.cookie.split('; ')
        if (array.length === 0) return ''
        const row = array.find((row) => row.startsWith(name + '='))
        if (!row) return ''
        const data = row.split('=')
        if (data.length > 1) return data[1]
        return ''
    }

    return {
        createSession,
        setupProfile,
        getProfile,
        setProfile,
        destroySession,
        isLoggedIn,
        getRefreshToken,
    }
}

export const setupProfile = (roles: ROLE_NAME[]): ROLE_NAME => {
    switch (true) {
        case roles.includes(ROLE_NAME.ADMIN) || roles.includes(ROLE_NAME.SUPER_ADMIN):
            localStorage.setItem('profile', ROLE_NAME.ADMIN)
            return ROLE_NAME.ADMIN
        case roles.includes(ROLE_NAME.MANAGER):
            localStorage.setItem('profile', ROLE_NAME.MANAGER)
            return ROLE_NAME.MANAGER
        case roles.includes(ROLE_NAME.TENANT):
            localStorage.setItem('profile', ROLE_NAME.TENANT)
            return ROLE_NAME.TENANT
        case roles.includes(ROLE_NAME.GUEST):
            localStorage.setItem('profile', ROLE_NAME.GUEST)
            return ROLE_NAME.GUEST
        default:
            localStorage.setItem('profile', ROLE_NAME.GUEST)
            return ROLE_NAME.GUEST
    }
}

export const getProfile = (): ROLE_NAME => {
    return (localStorage.getItem('profile') as ROLE_NAME) || ROLE_NAME.GUEST
}
