import React, { useEffect, useMemo } from 'react'
import { Route, useLocation, useHistory } from 'react-router-dom'
import PleaseWait from '../components/admin/account/PleaseWait'
import HomeLayout from '../app/layouts/HomeLayout'
import { useSelector } from 'react-redux'
import { ReduxState } from './general'
import routes from '../helpers/routes'
import { ROLE_NAME } from './roleName'

const AppLayout = React.lazy(() => import('../app/layouts/AppLayout'))

const ProfileRoute = () => {
    const profile = useSelector((state: ReduxState) => state.profile)
    const location = useLocation()
    const path = location.pathname
    const history = useHistory()

    const routeInfo = useMemo(
        () =>
            routes.find((route) =>
                route.path.includes(':')
                    ? path.includes(route.path.split(':')[0])
                    : route.path.includes(path)
            ),
        [ path]
    )
    const isAllowed = useMemo(
        () => routeInfo?.roles.includes(profile.role.name || ROLE_NAME.NONE),
        [routeInfo, profile]
    )

    useEffect(() => {
        if(!isAllowed) history.push('/')
    }, [history, isAllowed])

    if (profile.loggedIn === 'wait') {
        return <PleaseWait />
    } else if (profile.loggedIn === true) {
        return (
            <Route
                path="/"
                render={(props: any) => (
                    <AppLayout loggedIn={profile.loggedIn} {...props} />
                )}
            />
        )
    } else {
        return (
            <Route
                path="/"
                render={(props: any) => <HomeLayout {...props} />}
            />
        )
    }
}

export default ProfileRoute
