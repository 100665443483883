import React, { useState } from 'react'
import GuestLayout from '../../../app/layouts/GuestLayout'
import { Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AlertProps, get_axios_error } from '../../../helpers/general'
import AccountWrapper from './AccountWrapper'

function ResetForgottenPasswordRequest() {
    const [disabled, setDisabled] = useState(false)
    const [username, setUsername] = useState('')
    const [alert, setAlert] = useState<AlertProps>({
        show: false,
        message: '',
        variant: 'info',
        heading: 'Account Activation',
    })

    const usernameChanged = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setUsername(event.target.value)
    }

    const showAlert = (message: string, variant: string, heading = '') => {
        const data: AlertProps = { show: true, variant: variant, message: message }
        if (heading) data.heading = heading
        setAlert(data)
    }

    const hideAlert = () => {
        setAlert({ show: false })
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        setDisabled(true)
        const form = { username: username }

        window.axios.post('user-account/forgotten-password', form)
            .then(response => {
                showAlert(response.data.message, 'info')
            })
            .catch((error) => {
                const e = get_axios_error(error)
                if (e.statusCode === 502) {
                    showAlert('Service currently unavailable. Please try again later', 'danger')
                } else {
                    showAlert(e.message, 'danger')
                }
            })
            .finally(() => setDisabled(false))
    }

    return (
        <GuestLayout>
            <AccountWrapper>
                <div className='p-4 rounded'>
                    <div className='text-center'>
                        <h3 className=''>Reset Forgotten Password Request</h3>
                        <p className='mb-1'>Already have an account? <Link to={'/login'}>Sign in here</Link></p>
                        <p>Don't have an account yet? <Link to={'/create-account'}>Register here</Link></p>
                    </div>
                    <div className='form-body'>
                        <form className='row g-3' onSubmit={handleSubmit}>
                            <div className='login-separater text-center mb-4'>
                                <span>Enter your email address below</span>
                                <hr />
                                {alert.show &&
                                    <Alert className='text-start' variant={alert.variant} onClose={() => hideAlert()}
                                           dismissible>
                                        <Alert.Heading as={'h5'}>Reset Forgotten Password Request</Alert.Heading>
                                        <p className='mb-0'>{alert.message}</p>
                                    </Alert>}
                            </div>
                            <div className='col-12'>
                                <label htmlFor='inputEmailAddress' className='form-label'>Email Address</label>
                                <input type='email' className='form-control' id='inputEmailAddress'
                                       placeholder='Email Address' autoComplete='username'
                                       onChange={usernameChanged} value={username} required />
                            </div>

                            <div className='col-12'>
                                <div className='d-grid'>
                                    <button type='submit' className='btn btn-primary' disabled={disabled}>
                                        <i className='bx bxs-send bx-rotate-270' />Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </AccountWrapper>
        </GuestLayout>
    )
}

export default ResetForgottenPasswordRequest