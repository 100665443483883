import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import {
    resetSession,
    setLoginStatus,
    setupUser,
} from '../features/auth/ProfileSlice'
import { useAuth } from './auth'
import { ReduxState } from './general'
import axios from 'axios'

export const useLoggedIn = () => {
    const profile = useSelector((state: ReduxState) => state.profile)
    const dispatch = useDispatch()
    const { isLoggedIn, destroySession } = useAuth()

    const getUserProfile = useCallback(() => {
        axios
            .get('/profile')
            .then((response) => {
                if (response.data && response.data.id) {
                    dispatch(setupUser(response.data))
                } else {
                    console.log(response)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [dispatch])

    return () => {
        const loggedIn = isLoggedIn()
        if (loggedIn) {
            if (!profile.id) {
                getUserProfile()
            }
            dispatch(setLoginStatus(true))
        } else {
            dispatch(resetSession())
            destroySession()
            window.setTimeout(() => {
                const path = window.location.pathname
                if(path !== '/login' && path !== '/') window.location.pathname = '/'
            }, 1000)
        }
    }
}

export const useLogout = () => {
    const dispatch = useDispatch()
    const { destroySession } = useAuth()
    return () => {
        dispatch(resetSession())
        destroySession()
    }
}
