import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from '../../helpers/routes'
import LandlordHeader from '../client/landlord/LandlordHeader'
import Page from './Page'
import { ProfileInterface } from '../../features/auth/ProfileSlice'

const NotFound = React.lazy(() => import('../misc/NotFound'))

const loading = (
    <div className="py-5 text-center">
        <div
            className="spinner-grow"
            style={{ width: '3rem', height: '3rem' }}
            role="status"
        >
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
)

interface Props {
    profile: ProfileInterface
    style?: object | undefined
}

function MainContent({ profile }: Props) {
    return (
        <div className="page-wrapper">
            <LandlordHeader profile={profile} />
            <div className="page-content">
                <Suspense fallback={loading}>
                    <Switch>
                        {routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props) => (
                                    <Page title={route.name}>
                                        <route.component {...props} />
                                    </Page>
                                )}
                            />
                        ))}
                        <Route component={NotFound} />
                    </Switch>
                </Suspense>
            </div>
        </div>
    )
}

export default React.memo(MainContent)
