import React from 'react'

type Props = {
    children?: any
}

const AccountWrapper = (props: Props) => {
    return (
        <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
            <div className='container-fluid'>
                <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                    <div className='col mx-auto'>
                        <div className="mb-4 text-center">
                            <img src={'/assets/images/logo-img.png'} width="180" alt=""/>
                        </div>
                        <div className='card'>
                            <div className='card-body'>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountWrapper