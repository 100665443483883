import React from 'react';

type Props = {
	children ?: any
}

function GuestLayout(props:Props) {
	return (
		<React.Fragment>
			{props.children}
		</React.Fragment>
	);
}

export default GuestLayout;